import * as React from "react"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <PageTitle text="404" />
      <div>
        <p>Извините, запрашиваемая страница не существует</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
